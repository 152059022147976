/* eslint-disable no-return-assign, no-param-reassign, no-bitwise */
import Router from "next/router";
import * as Sentry from "@sentry/nextjs";
import add from "lodash/fp/add";
import castArray from "lodash/fp/castArray";
import chunk from "lodash/fp/chunk";
import cloneDeep from "lodash/fp/cloneDeep";
import compact from "lodash/fp/compact";
import compose from "lodash/fp/compose";
import concat from "lodash/fp/concat";
import curry from "lodash/fp/curry";
import curryRight from "lodash/fp/curryRight";
import difference from "lodash/fp/difference";
import differenceWith from "lodash/fp/differenceWith";
import entries from "lodash/fp/entries";
import filter from "lodash/fp/filter";
import find from "lodash/fp/find";
import first from "lodash/fp/first";
import flatten from "lodash/fp/flatten";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import groupBy from "lodash/fp/groupBy";
import identity from "lodash/fp/identity";
import intersection from "lodash/fp/intersection";
import isEqual from "lodash/fp/isEqual";
import isEqualWith from "lodash/fp/isEqualWith";
import isNull from "lodash/fp/isNull";
import isUndefined from "lodash/fp/isUndefined";
import join from "lodash/fp/join";
import keys from "lodash/fp/keys";
import last from "lodash/fp/last";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import noop from "lodash/fp/noop";
import omit from "lodash/fp/omit";
import omitBy from "lodash/fp/omitBy";
import orderBy from "lodash/fp/orderBy";
import partition from "lodash/fp/partition";
import pick from "lodash/fp/pick";
import reduce from "lodash/fp/reduce";
import reject from "lodash/fp/reject";
import replace from "lodash/fp/replace";
import size from "lodash/fp/size";
import some from "lodash/fp/some";
import startsWith from "lodash/fp/startsWith";
import toLower from "lodash/fp/toLower";
import toNumber from "lodash/fp/toNumber";
import toString from "lodash/fp/toString";
import toUpper from "lodash/fp/toUpper";
import uniqBy from "lodash/fp/uniqBy";
import memoizeOne from "memoize-one";
import { validate } from "@/cloverleaf-ui/utils";
import { track } from "../../lib/analytics";
import { publicRuntimeConfig } from "../../lib/config";
import { RESERVED_URL_PARAMETERS } from "../../lib/constants";
import { boundUpdateSelect } from "../../modules/boundActionCreators";
import cacheInBrowser from "./cacheInBrowser";
import {
  EVENT,
  MY_ORGANIZATION_TAB_DERIVED_STATUSES,
  SEGMENT_QUESTION_NUMBER,
  CloverleafTier,
  ConfigurationSetting
} from "./constants";
import { email as validateEmail } from "./validate";
const debug = require("debug")("cloverleaf:redirect");

/**
 * Export Additional Utils
 */
export {
  cacheInBrowser,
};
export * from "./getSubdomain";

/**
 * Common Getters
 */
export const getId = get("id");
export const getValue = get("value");
export const getName = get("name");
export const getCurrentUser = get(["data", "currentUser"]);
export const getCurrentUserId = get("currentUser.id");
export const getOrEmpty = getOr([]);
export const getOrString = getOr("");
export const getFirstId = compose(
  get("id"),
  first,
);
export const getOrganization = getOr({}, "organization");

/**
 *
 * @param {Object} urlObject
 * @param {URLSearchParams} urlObject.query object of type URLSearchParams
 * @returns {String} url
 */
const getReturnUrl = ({ query, path, subdomain, useLocalAPIPort = false }) => {
  const url = new URL(path, publicRuntimeConfig.CLOVERLEAF_CLIENT_URL);

  if (subdomain) {
    url.host = `${subdomain}.${url.host}`;
    // url.hostname = url.hostname.replace(/^[^.]*/, subdomain);
  }

  url.port = useLocalAPIPort ? 3001 : url.port || 443;

  // If url has query params, we must append instead
  if (url.search) {
    const urlSearchParams = new URLSearchParams(url.search);

    for (const [key, value] of query.entries()) {
      urlSearchParams.set(key, value);
    }

    url.search = urlSearchParams;
  } else {
    url.search = query;
  }

  return url.toString();
};

const getEnvUrl = ({
  query,
  path,
  subdomain,
  useLocalAPIPort,
}) => {
  if (subdomain) {
    query.set("subdomain", subdomain);
  }

  let envSubdomain = null;

  if (publicRuntimeConfig.CLOVERLEAF_FORCE_ORG_SUBDOMAINS === "true") {
    envSubdomain = subdomain;
  }

  return getReturnUrl({
    path,
    query,
    useLocalAPIPort,
    subdomain: envSubdomain,
  });
};

export const getBaseUrl = ({
  subdomain,
  path: _path,
  query: queryParams,
  isProduction, // flag to specify to use production urls
  useLocalAPIPort = false, // Local only, defines which port to use 3001 (API) or 3000 (Client)?
  locale: _locale,
} = {}) => {
  let sanitized = queryParams;

  if (sanitized && typeof sanitized === "object") {
    sanitized = omitIsEmpty(queryParams);
  }

  let path = "";

  // Add locale to path if not default locale...
  if (_locale && _locale !== "en") {
    path = `${_locale}`;
  }

  // Add path to localized path
  if (_path) {
    path = `${path}${_path}`
  }

  const query = new URLSearchParams(sanitized);

  if (isProduction || publicRuntimeConfig.CLOVERLEAF_ENV !== "development") {
    return getReturnUrl({ query, path, subdomain: subdomain || "app" });
  }

  /**
   * To use production-like subdomain routing in non-prod environments
   */
  if (publicRuntimeConfig.CLOVERLEAF_FORCE_ORG_SUBDOMAINS === "true") {
    if (publicRuntimeConfig.CLOVERLEAF_ENV === "development") {
      return getReturnUrl({ query, path, subdomain, useLocalAPIPort });
    }
  }

  return getEnvUrl({
    query,
    path,
    subdomain,
    useLocalAPIPort,
  });
};

export const uppercase = (label) => {
  if (typeof label === "string") {
    return label.toUpperCase();
  }

  return label;
};

/**
 * Generates an array of objects with a value and label property from an array of objects.
 * @param {Array} array Array of objects to map into
 * @param {String} valueKey Object Key for the value property. (Typically a unique identifier)
 * @param {String} labelKey Object Key for the label property. (Shown in select dropdown)
 */
export const mapObjSelectOptions = curryRight((objArray, valueKey, labelKey) =>
  (map(obj => ({ value: obj[valueKey], label: obj[labelKey] }), objArray)));

/**
 * Generates an array of objects with a value and label property from an array of non-objects.
 * @param {Array} array Array of non-objects to map into
 */
export const mapArraySelectOptions = map(entry => ({ value: entry, label: entry }));

export function resolveScopedStyles(scope) {
  return {
    className: scope.props.className,
    styles: scope.props.children,
    classes: className => `${scope.props.className} ${className}`,
  };
}

export const isEmpty = (obj) => {
  if (obj == null) return true;
  if (Array.isArray(obj) || typeof obj === "string") return obj.length === 0;

  return Object.keys(obj).length === 0;
};

const getErrorType = getOr("", "extensions.exception.name");
const getGraphQLErrors = getOr([], "graphQLErrors");
const checkAnyError = (errorType, errors) => some(error => getErrorType(error) === errorType, errors);

/**
 * Checks for a specified error in a GraphQL response
 * @example checkGraphQLError('PermissionError', gqlError)
 * @param {String} errorType
 * @param {ApolloError} errors
 */
export const checkGraphQLError = (errorType, errors) => checkAnyError(errorType, getGraphQLErrors(errors));

export const displayGraphQLMessage = message => message.replace(/^GraphQL error: /, "");

export const flattenObject = obj => flatten(Object.values(obj));

export const omitUndefined = omitBy(isUndefined);

export const omitIsEmpty = omitBy(isEmpty);

export const getSubdomainFromHeader = (ctx) => {
  if (ctx?.req?.headers) {
    return ctx?.req?.headers["cloverleaf-subdomain"];
  }

  return undefined;
};

export const urlWithParams = (url, req) => {
  let params = "";
  if (req) {
    params = req.search;
  }
  else if (typeof window !== "undefined") {
    params = window.location.search;
  }

  return `${url}${params}`;
};

export const parseEmails = (csv) => {
  const emails = [];
  const errors = {};
  const lines = csv.split("\n");
  const addresses = lines.map(line => line.split(",")[0]);
  addresses.forEach((address) => {
    const trimmedAddress = address.replace(/\s/g, "");
    const valid = !(validateEmail(trimmedAddress));
    if (!valid) {
      errors.validationError = "One or more email addresses are invalid";
    }
    emails.push({
      address: trimmedAddress,
      valid,
    });
  });

  return ({
    emails,
    errors,
  });
};

export const parseDomainFromEmail = email => {
  if (!email || validate.email(email)) return undefined; // invalid email

  return email.split("@").pop();
};

export const convertHexToRGBA = (hex, opacity = 1) => {
  const isHexValue = typeof hex === "string" && hex.charAt(0) === "#";

  if (!isHexValue) {
    return hex;
  }

  const trimmedHex = hex.replace("#", "");
  const r = parseInt(trimmedHex.substring(0, 2), 16);
  const g = parseInt(trimmedHex.substring(2, 4), 16);
  const b = parseInt(trimmedHex.substring(4, 6), 16);

  return (`rgba(${r},${g},${b},${opacity})`);
};

export const isEqualCaseInsensitive = isEqualWith((val1, val2) => toUpper(val1) === toUpper(val2));

export const isEqualId = isEqualWith((val1, val2) => toString(val1) === toString(val2));

export const pluralize = (word, quantity) => (quantity !== 1 ? `${word}s` : word);
export const makePossessive = str => str && (toLower(last(str)) === "s" ? `${str}'` : `${str}'s`);

/**
 * Retrieves initials from a persons name.
 * @param {String} name The person's first name or full name
 * @param {String} lastName The person's last name (optional)
 */
export const initialsFromName = (name, lastName) => {
  if (!name) return "";

  if (lastName) {
    return `${name.charAt(0)}${lastName.charAt(0)}`;
  }

  const parts = name.split(" ");
  const initials = parts.reduce((accumulator, part) => (
    !isEmpty(part) ? accumulator += part[0] : accumulator
  ), []);

  return `${initials[0]}${last(initials)}`;
};

export const personalityTypes = [
  "INFJ",
  "ISTJ",
  "INFP",
  "ISFJ",
  "INTJ",
  "ISTP",
  "INTP",
  "ISFP",
  "ENFJ",
  "ESTJ",
  "ENFP",
  "ESFJ",
  "ENTJ",
  "ESTP",
  "ENTP",
  "ESFP",
];

export const enneagramTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const emptyPersonalityTypeMappings = personalityTypes.reduce((accumulator, type) => {
  accumulator[type] = [];

  return accumulator;
}, {});

export const emptyEnneagramTypeMappings = enneagramTypes.reduce((accumulator, type) => {
  accumulator[type] = [];

  return accumulator;
}, {});

export const addEmptyPersonalityTypes = itemsByPersonalityType => ({
  ...emptyPersonalityTypeMappings,
  ...itemsByPersonalityType,
});

export const addEmptyEnneagramTypes = itemsByEnneagramType => ({
  ...emptyEnneagramTypeMappings,
  ...itemsByEnneagramType,
});

export const groupByPersonalityType = compose(
  addEmptyPersonalityTypes,
  groupBy("scores.personality.title.nameKey"),
);

export const groupByEnneagramType = compose(
  addEmptyEnneagramTypes,
  groupBy("scores.enneagram.title.nameKey"),
);

export const orderByUserCountAndSecondaryName = orderBy(["users.length", "secondaryName"], ["desc", "asc"]);

export const filterItemsWithUsers = filter(item => item.users.length > 0);

export const filterItemsWithoutUsers = filter(item => item.users.length === 0);

export const isAdminUser = user => user && user.hasAdminPermission;

export const filterItemsWithDiscScores = filter(item => !isEmpty(get("scores.disc.traits", item)));

export const filterItemsWithDiscTitles = filter(item => !isEmpty(get("scores.disc.title", item)));

export const filterItemsWithStrengthsFinderScores = filter(item => !isEmpty(get("scores.strengths.traits", item)));

export const filterItemsWithViaScores = filter(item => !isEmpty(get("scores.via", item)));

export const filterItemsWithCulturePulseScores = filter(item => !isEmpty(get("scores.culture.traits", item)));

export const filterItemsWithMotivatingValuesScores = filter(item => !isEmpty(get("scores.motivatingvalues.traits", item)));

export const filterItemsWithInstinctiveDrivesScores = filter(item =>
  !isEmpty(get("scores.instinctiveDrives.traits", item)) && !get("scores.instinctiveDrives.isSearching", item));

export const filterItemsWith16TypesScores = filter(item => !isEmpty(get("scores.personality.traits", item)));

export const filterItemsWithEnneagramScores = filter(item => !isEmpty(get("scores.enneagram.title.name", item)));

export const filterItemsWithEnergyRhythmScores = filter(item => !isEmpty(get("scores.energyRhythm.title.name", item)));

const AVATAR_BASE_IMG_SIZE = 10;

// 1 is the lowest value, if anything is passed lower than that then the value will be 3
const getCircleScale = scale => (scale > 0 ? scale : 3);

export const getCircleSize = curry((base, scale) => getCircleScale(scale) * base);

export const getCircleImgSize = getCircleSize(AVATAR_BASE_IMG_SIZE);

export const getCircleSizePx = curry((base, pixelCount) => `${getCircleSize(base, pixelCount)}px`);

export const getCircleImgSizePx = getCircleSizePx(AVATAR_BASE_IMG_SIZE);

/**
 * @param {Number} value The value you want to remap
 * @param {Number} currentMin The current minimum value
 * @param {Number} currentMax The current maxiumum value
 * @param {Number} newMin The desired new minimum value
 * @param {Number} newMax The desired new maximum value
 */
export const remap = ({
  value,
  currentMin,
  currentMax,
  newMin,
  newMax,
}) => {
  if (value > currentMax || value < currentMin) {
    return undefined;
  }

  if ((currentMax - currentMin) === 0) {
    return parseFloat(newMin, 10);
  }

  return parseFloat(((value - currentMin) / (currentMax - currentMin)) * (newMax - newMin) + newMin, 10);
};

export const isExternal = startsWith("http");

export const concatAll = concat.convert({ cap: false, fixed: false });

export const pluckId = map("id");
export const pluckEmail = map("email");
export const pluckName = map("name");

export const orderByCandidateName = orderBy(["isCandidate", "fullName"], ["desc", "asc"]);

export const compactKeysByEntries = reduce((acc, [key, value]) => {
  if (value) {
    return [...acc, key];
  }

  return acc;
}, []);

/**
 * @param {Object[]} arrayOfObjects Array of objects that you want to return with exclusions
 * @param {String[]|Number[]} arrayOfIds Array of ids that you want to exclude from the array of objects
 * @returns {Object[]} Array of objects with exclusions
 */
export const differenceById = differenceWith((obj, id) => isEqualId(obj?.id, id));

export const floatTo2Decimals = val => parseFloat(val.toFixed(2));

export const toInt = str => (str ? parseInt(str, 10) : null);

export const toFloat = str => (str
  ? floatTo2Decimals(toNumber(str))
  : null);

const removeQuestionPrefix = compose(toInt, replace("question-", ""));

export const formatAnswersWith = curry((score, answers) => {
  const questions = keys(answers);

  const mapQuestionAnswer = key => ({
    question: removeQuestionPrefix(key),
    [score]: answers[key],
  });

  return map(mapQuestionAnswer, questions);
});

export const getInsightsOfType = curry((selectedType, insights) =>
  filter(insight => insight.type === selectedType, insights));

export const distanceBetweenPoints = curry((x1, y1, x2, y2) => Math.sqrt(((x2 - x1) ** 2) + ((y2 - y1) ** 2)));

/**
 *  NOTE: For tests, use the wait located in `testUtils` or else your test will throw errors
 * @param {Number} amount - time to wait for
 */
export const wait = (amount = 0) => new Promise(resolve => setTimeout(resolve, amount));

/**
 * Filters array of objects whose values at the given
 * key have at least one match in the filter array
 * @param {String} key The key to filter the objects by
 * @param {Array} filterValues Array of values to match by for the filter
 * @param {Object[]} objects The data to filter
 */
export const filterObjectsSomeMatch = curry(
  (key, filterValues, objects) => reject(object =>
    isEmpty(intersection(castArray(filterValues), object[key])), objects),
);

/**
 * Filters array of objects whose values at the given
 * key match all of the items in the filter array
 * @param {String} key The key to filter the objects by
 * @param {Array} filterValues Array of values to match by for the filter
 * @param {Object[]} objects The data to filter
 */
export const filterObjectsEveryMatch = curry(
  (key, filterValues, objects) => filter(object =>
    difference(castArray(filterValues), object[key]).length === 0, objects),
);

export const includesById = curry(({ id }, array) => some(["id", id], array));

// flattens, does a uniqById, and then maps - i.e. a flatmap with a uniq step.
export const flatUniqueMap = curry((iteratee, objects) => compose(
  map(iteratee),
  uniqBy("id"),
  flatten,
)(objects));

export const normalize = str => str.replace(/[^a-zA-Z0-9. ]+/g, "").toLowerCase();

export const mapKeyValues = curry((pickBy, collection) => compose(
  map(pick(pickBy)),
)(collection));

export const updateUserSelectDirectly = (selectedUserID, options, prevOptions, selectName) => {
  const selectedOption = options.find(option => selectedUserID === option.value);

  if (!isEqual(options, prevOptions)) {
    if (selectedOption) {
      boundUpdateSelect(selectName, selectedOption);
    }
    else {
      boundUpdateSelect(selectName, undefined);
    }
  }
};

/**
 * Adjusts the value of the given color
 * @param {String} hexWithoutPound The hex value of the color to modify
 * @param {Number} percent The percent to lighten / darken the color by (-1 to 1)
 */
export const lightenDarkenColor = (hex, percent = 0) => {
  const hexWithoutPound = parseInt(hex.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;

  const R = hexWithoutPound >> 16;
  const G = (hexWithoutPound >> 8) & 0x00FF;
  const B = hexWithoutPound & 0x0000FF;

  return `#${(0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)}`;
};

// Chunks an array into two halves down the middle
export const bisectArray = array => chunk(Math.ceil(array.length / 2), array);

export const isTTIUser = get("isTTI");

export const isTTIOrganizationOwned = get("billingContact.organization.isTTI");

export const isTTIEntity = ({ user, team }) => {
  let isTTI = false;

  if (!user) {
    isTTI = team && isTTIOrganizationOwned(team);
  }

  if (!team) {
    isTTI = user && isTTIUser(user);
  }

  return isTTI;
};

export const discTitle = ({ user, team, title }) => {
  if (!title) return null;

  return (isTTIEntity({ user, team }) ? title.name : `${title.name}: ${title.secondaryName}`);
};

export const roundToMultipleOf = curry((multipleOf, value) =>
  (multipleOf > 0
    ? Math.round(value / multipleOf) * multipleOf
    : null));

export const getInnerWidth = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth;
  }

  return null;
};

export const doOnViewResize = (callback) => {
  if (typeof window !== "undefined") {
    window.onresize = callback;
  }

  return undefined;
};

export const findSelectOptionByLabel = (target, options) => find(({ label }) => isEqual(label, target), options);

export const omitTypename = omit("__typename");

// Do a "Safe Push", that will use external routing if needed.
// StrengthsFinder, etc. may link to a third-party site. Router.push throws errors for that.
export const safePush = curry((router, path) => (
  isExternal(path) ? () => window?.open(path) : () => router.push(path)
));

// Do a "Safe Replace", that will use external routing if needed.
export const safeReplace = curry((router, path) => (
  isExternal(path) ? () => {
    if (typeof window !== "undefined") {
      window.location.replace(path);
    }

    // Delay returning in case this is used in a promise-expecting method like getInitialProps
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    return promise;
  } : () => router.replace(path)
));

export const allTruthy = (...args) => compact(args).length === args.length;

/**
 * Toggle state at a specified key
 * @param {String} key - the key in the state that will be toggled
 * @param {Object} state - current state object that will be updated at the specified key
 */
export const toggleState = curry((key, state) => (typeof state[key] === "boolean"
  ? ({ [key]: !state[key] })
  : ({ [key]: state[key] })));

/**
 * Increases the numeric value by one
 * @param {String|Number} value - increment a string or number
 * @returns {Number}
 */
export const increment = compose(
  add(1),
  toNumber,
);

/**
 * Decreases the numeric value by one
 * @param {String|Number} value - decrement a string or number
 * @returns {Number}
 */
export const decrement = compose(
  add(-1),
  toNumber,
);

/**
 * Checks that two numeric values are equal
 * @param {String|Number} val1 - first value to check
 * @param {String|Number} val2 - second value to check
 * @returns {Boolean}
 */
export const isEqualIntVal = isEqualWith((val1, val2) => toNumber(val1) === toNumber(val2));

/**
 * Searches a string for a case-insensitive substring
 * @param {String} searchVal - substring to find
 * @param {String} data - string to search through
 * @returns {Boolean} boolean
 */
export const searchLower = (searchVal, data) => toLower(data).includes(toLower(searchVal));

/**
 * Calculates the last index of the array - Equivalent to array.length - 1
 * @param {Array} array
 * @returns {Number}
 */
export const lastIndex = (array = []) => decrement(size(array));

/**
 * Flips the boolean from false to true or true to false
 * @param {Boolean} bool - bool value to be inverted
 * @returns {Boolean}
 */
export const flipBoolean = bool => !bool;

export const isNullOrUndefined = val => isNull(val) || isUndefined(val);

// Check if we're likely on a mobile device
/**
 * @deprecated Use `@/cloverleaf-ui/utils
 */
export const isMobile = () => {
  try {
    return (typeof window !== "undefined" && typeof window.orientation !== "undefined") || (typeof navigator !== "undefined" && navigator.userAgent.indexOf("IEMobile") !== -1);
  }
  catch (err) {
    return false;
  }
};

export const pushToPricing = (name) => {
  if (typeof window !== "undefined") {
    track(EVENT.PricingLinkClicked.name, { location: name });
    window.location.href = "https://cloverleaf.me/pricing";
  }
};

export const getFirstNamePossessive = flow(
  getOrString("currentUser.firstName"),
  makePossessive,
);

export const getTeamFromPayload = curry((mutationName, payload) => getOr({}, ["data", mutationName, "team"], payload));

// Deep comparison of the last function invocation's arguments (useful for comparing arrays and objects)
export const memoizeDeep = memoizeOne(identity, isEqual);

// Replicate querystring.stringify from node.
export const queryStringify = flow(
  omitUndefined,
  mapValues(value => encodeURIComponent(value)), // encode all the values (such as email) if needed.
  entries, // get all the key/value pairs
  map(join("=")), // join them so we have an array of key=value
  join("&"), // join key=value with & so we have key=value&key2=value2...
);

/**
 * Get stringified query parameters based on reserved params
 *
 * @param {Object} queryParams query parameters
 */
export const getReservedQueryParameters = flow(
  pick(RESERVED_URL_PARAMETERS),
  omitUndefined,
  queryStringify,
);

// Returns an array where the first element is answers, second element is segmentAnswers
export const partitionSegmentAnswer = partition(answer => answer.question !== SEGMENT_QUESTION_NUMBER);

export const buildOrganizationHardRoute = ({ isPersonal, subdomain, path, queryString }) => (
  isPersonal
    ? getBaseUrl({ subdomain, path, query: "setPersonal=true" })
    : getBaseUrl({ subdomain, path, query: queryString }));

export const breadcrumbString = flow(
  pluckName,
  join(" / "),
);

export const canCopyProgrammatically = () => typeof window !== "undefined" && document?.queryCommandSupported?.("copy");

/**
 * Copies the text in a text area to the clipboard, must be used as the onClick event for a `textarea` element
 * @param {React.RefObject} textAreaRef
 * @param {Function} onSuccess
 */
export const copyToClipboard = (textAreaRef, onSuccess = noop) => (event) => {
  // This code should only be run client side
  if (typeof window !== "undefined" && document?.queryCommandSupported?.("copy")) {
    textAreaRef.current.select();
    document.execCommand("copy");
    event.target.focus();
    onSuccess();
  }
};

export const aliasFormat = compose(
  replace(/[^a-zA-Z0-9-]+/g, ""),
  toLower,
);

// A function that always returns null.
export const alwaysNull = () => null;

export const getUserInitials = (user) => {
  const { firstName, lastName, fullName, email } = user || {};

  if (firstName && lastName) {
    return `${first(firstName)}${first(lastName)}`;
  }

  if (fullName) {
    return initialsFromName(fullName);
  }

  return email ? first(email) : "";
};

export const findConfigurationByConfigId = (configurationId, configurations) => (
  find(configuration => isEqualCaseInsensitive(configuration.configurationId, configurationId), configurations)
);

export const getAssessmentsFromConfiguration = configuration => configuration.filter((option) => {
  const isAssessment = !!get("assessmentName", option);
  const status = get("adminSetting.setting", option);

  return isAssessment && (status === ConfigurationSetting.VISIBLE || status === ConfigurationSetting.ENABLED ||
                          status === ConfigurationSetting.REQUIRED);
});

export const isLastIndex = (index, array) => (isEmpty(array) ? false : (size(array) - 1) === index);

export const isEven = num => typeof num === "number" && num % 2 === 0;

/**
 * @param {Object} fieldsToUpdate - An object where the key is the field in the questions array that should be updated with the new optimistic value
 * @param {Number} questionIndex - Index of the current question in the questions array
 * @param {Array<Object>} questions - Array of the assessment questions
 *
 * @example buildOptimisticQuestions({ 'answer': 2 }, questionIndex, questions) // Where answer is the field that gets updated with a value of 2
 */
export const buildOptimisticQuestions = ({
  fieldsToUpdate,
  questionIndex,
  questions,
  isLastQuestion,
}) => {
  const isValidIndex = questions[questionIndex];

  if (!isValidIndex) {
    return questions;
  }

  // Clone the questions array, don't mutate
  const questionsCopy = cloneDeep(questions);

  // Loop through each of the fields and update the value of the entry to the new value
  entries(fieldsToUpdate).forEach(([fieldName, newValue]) => {
    questionsCopy[questionIndex][fieldName] = newValue;
  });

  // Ensure the answerId field is updated to automatically push to the next page, unless we're on the last question
  // The last question case ensures that we don't allow the user to move forward until we're ready to submit
  questionsCopy[questionIndex].answerId = isLastQuestion ? null : "Optimistic_Answer_ID";

  return questionsCopy;
};

export const isSetToVisible =
  configSetting => configSetting !== ConfigurationSetting.DISABLED;

export const replaceAll = (matchStr, replaceStr, str) => str.replace(new RegExp(matchStr, "gi"), replaceStr);

export const bypassNotificationPath = pathname => (
  [
    "/notifications",
    "/signin",
    "/signup",
    "/t",
    "/invite-members",
    "/my-dashboard",
  ].includes(pathname));

// If we're on the server, do a 302 redirect. If we aren't, do a client-side route.
export const redirect = ({ res }, location) => {
  if (res) {
    // This is server-side, so we do a server-side redirect.
    // But only if the response isn't done - in parallel requests then it's possible this gets called 10 times, but we only need one redirect.
    if (!res.finished) {
      res.writeHead(302, { Location: location });

      return res.end();
    }
    debug("Request is finished, so no need to actually redirect anymore.");

    return null;
  }

  if (typeof window !== "undefined") {
    // Do a client-side redirect.
    return safeReplace(Router, location)();
  }

  Sentry.addBreadcrumb({
    category: "utils/redirect",
    message: "Attempting to redirect on client but no res object. Swallowing.",
    level: "log",
  });

  return null;
};

export const buildRedirect = (ctx, location) => uri => redirect(ctx, uri || location);

const { active, inactive, trial } = MY_ORGANIZATION_TAB_DERIVED_STATUSES;
export const isInactiveMyOrganizationStatus = org => inactive.includes(org?.plgPlan?.derivedStatus);
export const isActiveMyOrganizationStatus = org => active.includes(org?.plgPlan?.derivedStatus);
export const isTrialMyOrganizationStatus = org => trial.includes(org?.plgPlan?.derivedStatus);
export const isPromoTier = org => org?.plgPlan?.cloverleafTier === CloverleafTier.PROMO;
export const hasPromoPlan = org => !!org?.plgPlan?.promotion?.promotion?.promotionId;
