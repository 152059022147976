import { shape } from "prop-types";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  personalityScore,
  discScore,
  strengthsFinderScore
} from "./propTypes";
import { scoresFragment } from "./scores";

export const userScoresFragment = gql`
  fragment UserScoresFragment on User {
    scores {
      ...ScoresFragment
    }
  }
  ${scoresFragment}
`;

export const dailyCoachingFragment = gql`
  fragment DailyCoachingFragment on User {
    dailyCoaching {
      id
      date
      userId
      stages {
        id
        stage
        status
        data {
          user {
            id
            fullName
            avatar
            profile {
              id
              relativePublicUrl
            }
          }
          insight {
            id
            userInsightId
            message
            insightId
            isHelpful
            type
            target
            tags {
              name
              label
            }
          }
        }
      }
      currentStage {
        id
        stage
        status
        data {
          user {
            id
            fullName
            avatar
            profile {
              id
              relativePublicUrl
            }
          }
          insight {
            id
            userInsightId
            message
            insightId
            isHelpful
            type
            target
            tags {
              name
              label
            }
          }
        }
      }
      getsDailyCoaching
    }
  }
`;

export const currentUserFragment = gql`
  fragment CurrentUser on User {
    id
    stateData {
      resultToggles {
        interacted
      }
      componentPreferences {
        userId
        viewType
        preferences {
          componentId
          componentRank
          collapsed
          isCollapsible
          colSpan
        }
      }
    }
    isCloverleafAdmin
    hasAdminPermission
    admin {
      teams {
        id
      }
    }
    isSSO
    numberOfAssessmentsTaken
    avatar
    firstName
    lastName
    fullName
    email
    emails(includePrimary: false)
    role
    isCandidate
    isLead
    inactive
    locale
    timezone
    teamUserType
    organization {
      id
      name
      alias
      logo
    }
    primaryOrganization {
      id
      name
    }
    companyName
    connectedAccounts {
      id
      type
      email
    }
    isTTI
    profile {
      id
      publicUrl
      relativePublicUrl
    }
    attributes{
      key
      label
    }
    isPLG
    ...UserScoresFragment
  }
  ${userScoresFragment}
`;

export const currentUserQuery = gql`
  query currentUserQuery {
    currentUser {
      ...CurrentUser
    }
  }
  ${currentUserFragment}
`;

export const currentUserTeamsQuery = gql`
  query currentUserTeamsQuery {
    currentUser {
      id
      isLead
      isCloverleafAdmin
      isIncludedOnAnyTeam
      defaultTeam {
        id
        name
      }
      organization {
        id
      }
      teams {
        id
        name
        hidden
        organization {
          id
          breadcrumbString
        }
      }
      plgPlan {
        isActive
        inTrial
        trialDaysLeft
        isExpired
        planId
        subscriptionId
        isPlanOwner
        planOwner {
          id
        }
      }
      isPLG
      admin {
        teams {
          id
          name
          hidden
          organization {
            id
            breadcrumbString
          }
        }
      }
    }
    check {
      canViewCreateOrganizationTeam
    }
  }
`;

export const withCurrentUser = graphql(currentUserQuery, { options: { errorPolicy: "all" } });

export const updateProfileMutation = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      locale
      timezone
      companyName
      firstName
      lastName
      fullName
      profile {
        id
        relativePublicUrl
        publicUrl
      }
      role
      teamUserType
      manager {
        id
        avatar
        fullName
        firstName
        lastName
        profile {
          id
          relativePublicUrl
        }
      }
    }
  }
`;

export const withSetAdditionalEmails = graphql(gql`
  mutation setAdditionalEmail($input: SetAdditionalEmailInput!) {
    setAdditionalEmail(input: $input) {
      success
      user {
        id
        emails(includePrimary: false)
      }
    }
  }
`, {
  props: ({ mutate }) => ({
    setAdditionalEmails: input => mutate({
      variables: { input },
    }),
  }),
});

export const withRemoveConnectedAccount = graphql(gql`
  mutation removeConnectedAccount($input: RemoveConnectedAccountInput!) {
    removeConnectedAccount(input: $input) {
      success
      user {
        id
        calendar {
          id
          settings {
            connectedAccounts {
              id
              type
              email
            }
          }
        }
        connectedAccounts {
          id
          type
          email
        }
      }
    }
  }
`, {
  props: ({ mutate }) => ({
    removeConnectedAccount: input => mutate({
      variables: { input },
    }),
  }),
});

export const userPropTypes = {
  data: shape({
    currentUser: shape({
      scores: shape({
        personality: personalityScore,
        disc: discScore,
        strengths: strengthsFinderScore,
      }),
    }),
  }).isRequired,
};
