var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hi9y_1ijTv7VhsxpRalg4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { publicRuntimeConfig } from "./lib/config";

Sentry.init({
  dsn: "https://c1f56fdd8d0c4c02a0f8d4eaf7158f0f@o183794.ingest.sentry.io/1276209",

  environment: publicRuntimeConfig.CLOVERLEAF_ENV,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: publicRuntimeConfig.CLOVERLEAF_ENV === "development" ||
    publicRuntimeConfig.CLOVERLEAF_ENV === "test" ? 1.0 : 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0, // 100%

  // Enables Performance Monitoring
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/#enable-tracing
  tracesSampleRate: 0.05, // 5%

  // https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
  normalizeDepth: 5,

  integrations: [
    Sentry.replayIntegration({
      /**
       * Configuration
       * https://docs.sentry.io/platforms/javascript/session-replay/configuration
       */
      inlineImages: false,
      /**
       * Privacy Configuration
       * https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
       */
      maskAllText: false,
      maskAllInputs: false,
      maskInputOptions: {
        email: true,
        password: true,
      },
    }),

    Sentry.breadcrumbsIntegration({
      dom: {
        serializeAttribute: [
          "data-testid",
          "aria-label",
        ],
      },
    }),
  ],

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Using beforeSend to filter errors
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#using-beforesend
  beforeSend(event, hint) {
    const error = hint.originalException;

    // Ignore network errors
    if (
      error?.message &&
      error.message.match(/Response not successful/i)
    ) {
      return null;
    }

    // This should be included in network errors, but ignore failed login attempts
    if (
      event?.message &&
      event.message.match(/Login Invalid/)
    ) {
      return null;
    }

    /**
     * @todo - Emergency Use - exclude javascript errors on /signin from going to Sentry
     * Temporarily ease noise from vulnerability scanners hitting the signin page
     */
    if (event?.request?.url?.includes("/signin")) {
      if (event?.exception?.values && event.exception.values.length) {
        if (event.exception.values[0].type === "ReferenceError"
          || event.exception.values[0].type === "TypeError"
          || event.exception.values[0].type === "SyntaxError"
        ) {
          return null
        }
      }
    }

    if (event.request && event.request.url) {
      // Mask token if present in the URL
      const url = new URL(event.request.url);
      if (url.searchParams.has("token")) {
        url.searchParams.set("token", "****");
        event.request.url = url.toString();
      }
    }

    return event;
  },

  // Prevent the following errors from reporting to Sentry
  ignoreErrors: [
    /Received status code 401/,
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic issue when third-party tools get blocked or fetch throws for some reason
    "Failed to fetch",
    // See https://stackoverflow.com/a/60860369/850291
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
    // See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    "ResizeObserver loop limit exceeded",
    // See https://bugs.webkit.org/show_bug.cgi?id=231150
    "Load failed",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
